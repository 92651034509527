import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import "core-js/modules/esnext.array-buffer.detached.js";
import "core-js/modules/esnext.array-buffer.transfer.js";
import "core-js/modules/esnext.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/web.dom-exception.stack.js";
import { defineComponent, reactive, toRefs, onMounted, watch } from "vue";
import * as echarts from "echarts";
import sleep from "@/utils/sleep";
export default defineComponent({
  name: "Echart",
  props: {
    data: {
      type: Object,
      default: () => ({
        total: null,
        normalNum: null,
        abnormalNum: null,
        unMeasured: null
      })
    },
    legend: {
      type: Boolean,
      default: true
    },
    label: {
      type: Boolean,
      default: false
    },
    center: {
      type: Object,
      default: () => ["28%", "50%"]
    }
  },
  setup(props) {
    const refData = reactive({
      echartRefs: null,
      myChart: null,
      loading: true
    });
    watch(() => props.data, val => {
      handleOptions();
    });
    //设置参数
    const handleOptions = () => {
      refData.myChart = echarts.init(refData.echartRefs);
      let option = {
        legend: {
          show: props.legend,
          orient: 'vertical',
          right: 10,
          top: 'center',
          textStyle: {
            marginBottom: 10
          }
        },
        tooltip: {
          show: true,
          trigger: 'item',
          textStyle: {
            color: '#000000',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'sans-serif',
            fontSize: 14,
            backgorund: "#FFF"
          }
        },
        color: ['#56A3FC', '#47D468', '#FF944D'],
        series: [{
          name: '',
          type: 'pie',
          radius: props.label ? '86%' : ['86%', '40%'],
          center: props.center,
          label: {
            normal: {
              show: props.label,
              formatter: e => {
                return `${e.data.name}：${e.data.value}`;
              },
              borderWidth: 20,
              borderRadius: 4
            }
          },
          data: [{
            value: props.data.unMeasured,
            name: `待测人数${props.label ? '' : `：${props.data.unMeasured}`}`
          }, {
            value: props.data.normalNum,
            name: `测温正常人数${props.label ? '' : `：${props.data.normalNum}`}`
          }, {
            value: props.data.abnormalNum,
            name: `测温异常人数${props.label ? '' : `：${props.data.abnormalNum}`}`
          }]
        }]
      };
      option && refData.myChart.setOption(option);
      refData.loading = false;
      // 处理点击事件并且跳转到相应的百度搜索页面
    };
    //自适应大小
    const handleResizeChart = () => {
      refData.myChart && refData.myChart.resize();
    };
    //初始化
    const init = async () => {
      await sleep(2000);
      handleOptions();
      window.addEventListener("resize", handleResizeChart);
    };
    //echart转图片
    const echartToImg = () => {
      return dataURLtoFile(refData.myChart.getDataURL({
        type: "png",
        pixelRatio: 1,
        backgroundColor: '#fff'
      }), new Date().getTime());
    };
    //base64位转file
    const dataURLtoFile = (dataurl, filename) => {
      let arr = dataurl.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      //转换成file对象
      return new File([u8arr], filename, {
        type: mime
      });
    };
    onMounted(() => {
      init();
    });
    return {
      ...toRefs(refData),
      echartToImg,
      init
    };
  }
});