import { defineComponent, reactive, toRefs, nextTick } from "vue";
import { ElCheckbox, ElEmpty, ElPagination } from "element-plus";
import Echart from "./Echart.vue";
import API from "@/api/report/index";
export default defineComponent({
  name: 'TemperatureEchart',
  components: {
    ElCheckbox,
    Echart,
    ElEmpty,
    ElPagination
  },
  setup(props, {
    emit
  }) {
    const refData = reactive({
      total: 0,
      pageSize: 25,
      currentPage: 1,
      checkbox: false,
      active: null,
      list: [],
      orgId: null,
      schoolId: null,
      time: [],
      echartRefs: null,
      loading: true
    });
    //获取数据
    const getData = async () => {
      refData.loading = true;
      const {
        data,
        msg,
        code
      } = await API.trackinfoStatistics({
        holdid: refData.orgId,
        beginTime: refData.time[0] + ' 00:00:00',
        endTime: refData.time[1] + ' 23:59:59',
        pageNo: refData.currentPage,
        pageSize: refData.pageSize
      });
      refData.total = data.total;
      refData.list = [];
      refData.list = data.data.map(item => {
        return {
          ...item,
          cheboxck: false,
          refs: null
        };
      });
      if (refData.schoolId) {
        refData.active = data.data[0];
      } else {
        refData.active = null;
      }
      nextTick(() => {
        refData.list.map(item => {
          item.refs && item.refs.init();
        });
        refData.echartRefs && refData.echartRefs.init();
      });
      refData.loading = false;
    };
    //上传图片
    const handleUpload = async () => {
      const {
        data,
        msg,
        code
      } = await API.uploadPic({
        file: refData.echartRefs.echartToImg()
      });
      code == 0 && handleExport(data);
    };
    //导出
    const handleExport = async data => {
      await API.trackinfoExportExcel({
        data,
        holdid: refData.active.orgId,
        objectname: '',
        tracktype: 2,
        beginTime: refData.time[0] + ' 00:00:00',
        endTime: refData.time[1] + ' 23:59:59'
      });
    };
    //详情
    const handleInfo = item => {
      refData.active = item;
      refData.orgId = item.orgId;
    };
    //分页
    const currentPageChange = val => {
      refData.currentPage = val;
      getData();
    };
    const pageSizeChange = val => {
      refData.pageSize = val;
      getData();
    };
    return {
      ...toRefs(refData),
      getData,
      handleExport,
      handleUpload,
      pageSizeChange,
      currentPageChange,
      handleInfo
    };
  }
});